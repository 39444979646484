import { useState, useRef } from "react";
import letterIcon from "../img/letter-icon.png";

const Ranking = ({ videos, metadata, isLoading, isError, isMobile, viewportWidth }) => {
  // sett table filtering
  // default state: no filtering
  // filtering can be controlled by adding query params
  const urlParams = new URLSearchParams(window.location.search);
  const [tableFilterConfig, setTableFilterConfig] = useState({
    channelIds: [],     // array of channelIds, if empty no filtering is applied
    uploadDateRange: urlParams.get("uploadDateRange") || "",    // "all", "last week", "last month", "last year"
    keyword: urlParams.get("keyword") || "",                    // apply keyword (exact but case insensitive regex text match)
    sort: {
      column: urlParams.get("sortColumn") || "#",         // "#", "who", "posted", "roi-btc", "roi-usd"
      direction: urlParams.get("sortDirection") || "asc", // "asc" ord "desc"
    }
  });

  // define table header objects
  const headers = [
    {
      id: "#",
      displayNameHTML: "#",
      sorting: true
    },
    {
      id: "who",
      displayNameHTML: "Who",
      sorting: true
    },
    {
      id: "video",
      displayNameHTML: "Video",
      sorting: false
    },
    {
      id: "coins-called",
      displayNameHTML: "Coins\nCalled",
      sorting: false
    },
    {
      id: "posted",
      displayNameHTML: "Posted",
      sorting: true
    },
    {
      id: "roi-btc",
      displayNameHTML: "ROI\nbtc",
      sorting: true
    },
    {
      id: "roi-usd",
      displayNameHTML: "ROI\nusd",
      sorting: true
    },
  ];

  const filterByKeyword = (videos, keyword) => {
    const videosFiltered = videos.filter((video) => {
      let keywordStr = `${video.channel.name} `;
      keywordStr += `${video.channel.name.split(" ").join("")} `;
      keywordStr += `${video.title} `;
      keywordStr += `${video.coins.map((coin) => coin.symbol).join(" ")} `;
      // const escapedKeyword = keyword.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"); // I think this is not needed?
      return keywordStr.match(new RegExp(keyword, "i")) !== null;
    });
    return videosFiltered;
  }

  // Helper function to transform dateStr into UNIX timestamp
  // e.g. "2021-01-23" => 1611360000
  // input is not checked, caution: dateStr has to be in correct format
  const dateStrToUNIX = (dateStr) => {
    return Number(new Date(dateStr)) / 1000;
  }
  // get current UNIX date
  const todayUNIX = () => {
    return Number(new Date()) / 1000;
  }
  // get UNIX date threshhold for categories "thisWeek", "thisMonth" and "thisYear"
  const getThreshholdUNIX = (uploadDateRange) => {
    return {
      thisWeek: todayUNIX() - (7 * 24 * 60 * 60),   // thisWeek = last 7 days
      thisMonth: todayUNIX() - (31 * 24 * 60 * 60), // thisMonth = last 31 days
      thisYear: todayUNIX() - (365 * 24 * 60 * 60), // thisYear = last 365 days
    }[uploadDateRange] || 0; // set default value to 0 (= select all videos, no filtering)
  }

  /**
   * returns a filtered array of videos based on the current state of `tableFilterConfig`
   * filtering algorithm:
   * (1) filter by keyword
   * (2) ...then filter by channelId
   * (3) ...then filter by uploadDateRange ("thisWeek", "thisMonth", "thisYear", "all")
   * (4) ...then apply sorting
   * @returns {<Array>} of video objects
   */
  const filterVideos = (videos) => {
    if (isLoading) { // if data is not loaded yet
      return [];
    }
    // load filter settings
    // + copy all loaded videos
    const { channelIds, uploadDateRange, keyword, sort } = tableFilterConfig;
    let videosFiltered = [...videos];
    // (1) filter by keyword
    if (keyword) {
      videosFiltered = filterByKeyword(videos, keyword);
    }
    // (2) filter by channelId
    if (channelIds.length > 0) {
      videosFiltered = videosFiltered.filter(video => channelIds.includes(video.channel.id));
    }
    // (3) filter by uploadDate
    // threshhold for time intervalls. UNIX need to be at least threshhold for video to be filtered
    videosFiltered = videosFiltered.filter(video => {
      const uploadDateUNIX = dateStrToUNIX(video.uploadDate);
      const threshholdUNIX = getThreshholdUNIX(uploadDateRange);
      return uploadDateUNIX >= threshholdUNIX;
    });
    // (4) apply sorting
    switch (sort.column) {
      case "#":
      case "roi-btc": { // default setting
        videosFiltered = videosFiltered.sort((a, b) => b.ROIs.btc - a.ROIs.btc);
        break;
      }
      case "who": {
        videosFiltered = videosFiltered.sort((a, b) => a.channel.name > b.channel.name ? 1 : -1);
        break;
      }
      case "posted": {
        videosFiltered = videosFiltered.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));
        break;
      }
      case "roi-usd": {
        videosFiltered = videosFiltered.sort((a, b) => b.ROIs.usd - a.ROIs.usd);
        break;
      }
      // no default
    }
    // add number count and apply sorting direction
    videosFiltered.forEach((video, indx) => video.rank = indx + 1);
    videosFiltered = sort.direction === "asc"
      ? videosFiltered
      : videosFiltered.reverse();
    // return filtered value
    return videosFiltered;
  }

  // const resetKeyword = () => {
  //   const configClone = { ...tableFilterConfig };
  //   configClone.keyword = "";
  //   setTableFilterConfig(configClone);
  //   // remove queryparams from addressbar
  //   // @todo
  // }

  // always apply filtering before rendering component
  const videosFiltered = filterVideos(videos);
  return (
    <div className="ranking-container">
      <h1 className="page-title">Ranking</h1>
      {metadata.videos && tableFilterConfig.keyword === "" && (
        <p className="subtitle">{metadata.videos} videos by {metadata.youtubers} unique youtube channels found.</p>
      )}
      {/* <input type="text" onKeyUp={filterTableOnKeypress}></input> */}
      <table className="ranking-table">
        <colgroup>
          {headers.map((tableHeaderObj, indx) => (
            <col key={indx} className={tableHeaderObj.id} />
          ))}
        </colgroup>
        <thead>
          <tr>
            {headers.map((tableHeaderObj, indx) => (
              <TableHeaderCell
                key={indx}
                id={tableHeaderObj.id}
                displayNameHTML={tableHeaderObj.displayNameHTML}
                sorting={tableHeaderObj.sorting}
                tableFilterConfig={tableFilterConfig}
                setTableFilterConfig={setTableFilterConfig}
              />
            ))}
          </tr>
        </thead>
        <tbody>
          {videosFiltered.map((video, index) => (
            <TableRow
              key={index}
              rowIndex={index}
              video={video}
              isMobile={isMobile}
              viewportWidth={viewportWidth}
            />
          ))}

        </tbody>
      </table>
      {isLoading && (
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      )}
      {isError && (
        <p style={{marginTop: "15px"}}>Service is currently down</p>
      )}
      {!isLoading && tableFilterConfig.keyword && (
        <a href="/ranking" className="remove-all-link-styling">
          <div className="see-all-videos-bttn">
            See all Videos
          </div>
        </a>
      )}
    </div>
  );
};

const TableHeaderCell = ({ id, displayNameHTML, sorting, tableFilterConfig, setTableFilterConfig }) => {
  // compute header state (depending on tableFilterConfig and if sorting is enabled)
  // possible states
  // => "disabled": no arrows
  // => "inactive": both arrows greyed out
  // => "asc"     : ascending order (show only arrow up)
  // => "desc"    : descending order (show only arrow down)
  let sortingArrows = "disabled"; // column is not sortable
  if (sorting) { // sorting is enabled for this column
    if (tableFilterConfig.sort.column === id) { // column selected
      sortingArrows = tableFilterConfig.sort.direction; // get "asc" or "desc"
    } else {
      sortingArrows = "inactive" // not active
    }
  }

  const setSorting = () => {
    if (!sorting) { // do nothing if sorting is disabled
      return;
    }
    const configClone = { ...tableFilterConfig };
    // toggle asc and desc if already selected
    if (tableFilterConfig.sort.column === id) {
      configClone.sort.direction = configClone.sort.direction === "asc" ? "desc" : "asc";
    } else { // set sorting sortingArrows
      configClone.sort.column = id;
      configClone.sort.direction = "asc";
    }
    setTableFilterConfig(configClone);
  }

  return (
    <th
      onClick={setSorting}
      className={`${id} no-select ${sortingArrows !== "disabled" ? "pointer" : ""}`}
    >
      {sortingArrows === "disabled"
        ? (
          <div style={{ whiteSpace: 'pre-line' }}>
            {displayNameHTML}
          </div>
        ) : (
          <div className="th-cell-container">
            <div style={{ whiteSpace: 'pre-line' }}>
              {displayNameHTML}
            </div>
            {sortingArrows !== "disabled" && (
              <div className={`arrows-container ${sortingArrows}`}>
                <div className="arrow-up"></div>
                <div className="arrow-down"></div>
              </div>
            )}
          </div>
        )
      }
    </th>
  )
}

const TableRowWeeklyDigestRow = ({ viewportWidth }) => {
  const emailInputEl = useRef(null);
  const emailIsValid = emailStr => {
    const re = /\S+@\S+\.\S+/; // https://stackoverflow.com/a/9204568/6272061
    return re.test(emailStr);
  }
  const addEmailSubscriptionHTTP = (email) => {
    try {
      const url = `https://us-central1-shillcoin.cloudfunctions.net/addEmailSubscription?email=${email}`;
      let headers = new Headers();
      headers.append('Content-Type', 'application/json');
      headers.append('Accept', 'application/json');
      headers.append('GET', 'POST', 'OPTIONS');
      fetch(url, {
        mode: 'no-cors',
        method: 'GET',
        headers: headers,
      });
      // DO NOT WAIT FOR RESPONSE
      // @TODO find out how to wait for response
      return "ok";
    } catch(error) {
      return "error";
    }
  }
  const submitEmail = async () => {
    const emailInput = emailInputEl.current.value;
    if (!emailIsValid(emailInput)) {
      alert(`Invalid Email '${emailInput}'. Please try again or contact hello@shillcoin.info`);
    } else {
      addEmailSubscriptionHTTP(emailInput);
      alert(`Email '${emailInput}' added 🎉 Please check your inbox 📫`);
      emailInputEl.current.value = "";
    }
  }
  let colspan = 5;
  if (viewportWidth <= 720) {
    colspan = 4;
  }
  if (viewportWidth <= 610) {
    colspan = 3;
  }
  if (viewportWidth <= 500) {
    colspan = 2;
  }
  return (
    <tr className="weekly-digest-add">
      <td className="#"></td>
      <td className="who">
        <div>
          <img className="letter-emoji" src={letterIcon} alt="asd"></img>
        </div>
      </td>
      <td className="add-content" colSpan={colspan}>
        <div className="flex justify-center align-center">
          <div className="text">
            <h1>Get Weekly Ranking</h1>
            <p>Recieve last weeks best altcoin picks to your inbox</p>
          </div>
          <div className="emoji">
            <h1>👉</h1>
          </div>
          <div className="email-input">
            <input placeholder="enter email" type="email" ref={emailInputEl} onKeyDown={(e) => e.key === "Enter" ? submitEmail() : null}></input>
          </div>
          <div className="submit-email-bttn" onClick={submitEmail}>
            subscribe
          </div>
        </div>
      </td>
    </tr>
  );
}

const TableRow = ({ video, isMobile, rowIndex, viewportWidth }) => {
  const rank = video.rank;
  const channelName = video.channel.name;
  const channelLogo = video.channel.logo;
  const channelUrl = video.channel.url;
  const videoUrl = video.url;
  const title = video.title;
  const coins = video.coins.map((coin) => coin.symbol.toUpperCase());
  const today = new Date();
  const posted = new Date(video.uploadDate);

  const differenceInDays = (startDate, endDate) => {
    let differenceInTime = endDate.getTime() - startDate.getTime();
    let differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return Math.round(differenceInDays);
  };

  return (
    <>
      <tr>
        <td className="#">{rank}</td>
        <td className="who">
          <a href={channelUrl} rel="noreferrer" target="_blank">
            <img className="avatar" src={channelLogo} alt={channelName}></img>
          </a>
        </td>
        <td className="video">
          <div className="video-title-and-button-section">
            <p className="channel-name text-elipsis">{channelName}</p>
            <div>
              <a className="remove-all-link-styling" href={videoUrl} rel="noreferrer" target="_blank">
                <p className="watch-video">watch video</p>
              </a>
            </div>
          </div>
          <p className="video-title text-elipsis">Video: {title}</p>
        </td>
        <td className="ranking-entry-coins coins-called">{coins.join(", ")}</td>
        <td className="posted">{differenceInDays(posted, today)} days ago</td>
        <TableCellROI key={0} symbol="btc" roi={video.ROIs["btc"]} indx="first" isMobile={isMobile} />
        <TableCellROI key={1} symbol="usd" roi={video.ROIs["usd"]} indx="second" isMobile={isMobile}/>
      </tr>
      {(rowIndex === 14) && (!isMobile) && // insert weekly digest add to table once index 14 is reached!
        <TableRowWeeklyDigestRow
          viewportWidth={viewportWidth}
        />
      }
    </>
  );
};

const TableCellROI = ({ symbol, roi, indx, isMobile }) => {
  const currency = symbol.toUpperCase();
  const startValueDict = {
    "BTC": 1,
    "USD": 1000,
  };
  const startValue = startValueDict[currency];
  let todayValue = (startValue * ((roi/100) + 1));
  todayValue = currency === "BTC" ? todayValue.toFixed(2) : todayValue.toFixed(0);
  const redOrGreen = roi.toFixed(0) >= 0 ? "green" : "red";
  return (
    <td className={`roi roi-${symbol} pos-relative`}>
      <div className={`roi-badge ${redOrGreen}`}>
        <div className="flex justify-center align-center no-select">
          {roi.toFixed(0)}<span className="percentage-text">%</span>
          { !isMobile && (
            <>
              <div className={`info-badge circle-icon-info flex justify-center align-center ${redOrGreen}`}>
                <p className="no-select">info</p>
              </div>
              <div className={`roi-tooltip ${indx}`}>
                <p>If you had invested
                  <span className="currency-value">{startValue} {currency.toUpperCase()}</span><br />
                  you would now have
                  <span className="currency-value">{todayValue} {currency}</span><br />
                  resulting in an ROI of <span className={`currency-value ${redOrGreen}`}>{roi.toFixed(0)}%</span><br />
                </p>
                <p className="disclaimer">
                  (if you had inversted in all coins equally<br />
                  by the time the video was uploaded)
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    </td>
  )
}

export default Ranking;
