import { Link } from "react-router-dom";

const EmailUnsubscription = () => {
  return (
    <div>
      <div className="error404-container">
        <div className="error404-title">
          Unsubscribed from Weekly Update
        </div>
        <div className="error404-text">
          We are sorry our weekly update does not please you. You will no longer recieve any emails. For any feedback reach out to
        </div>
        <a className="shillcoinbets-email-reachout" href="mailto:hello@shillcoin.info">hello@shillcoin.info</a>
        <br></br>
        <br></br>
        <br></br>
        or navigate
      </div>

      <div className="center-button">
        <div className="shillcoin-bttn">
          <Link to="/ranking">Back to Ranking</Link>
        </div>
      </div>
    </div>
  );
};

export default EmailUnsubscription;
