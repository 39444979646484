
class EmailHelper {

  static sendMailtoContact() {
    const email = {
      subject: "👋 Hello",
      body: "",
    };
    window.location.href = EmailHelper.buildMailtoString("hello@shillcoin.info", email.subject, email.body);
  };

  static sendMailtoSubmitNFT() {
    const email = {
      subject: "👋 NFT Submission",
      body: "I would like to submit the following NFT collection:\n💥 {PLEASE_PASTE_LINK_TO_NFT_COLLECTION_HERE} 💥",
    };
    window.location.href = EmailHelper.buildMailtoString("hello@shillcoin.info", email.subject, email.body);
  };

  static sendMailtoSubmit() {
    const email = {
      subject: "🎥 Shillcoin Video Submission",
      body: "Hello,\nI would like to submit the following video to shillcoin.info:\nyoutube-link (required): \ncoins called (optional):\n",
    };
    window.location.href = EmailHelper.buildMailtoString("hello@shillcoin.info", email.subject, email.body);
  };

  static mailtoSubmitString() {
    const email = {
      subject: "🎥 Shillcoin Video Submission",
      body: "Hello,\nI would like to submit the following video to shillcoin.info:\nyoutube-link (required): \ncoins called (optional):\n",
    };
    return EmailHelper.buildMailtoString("hello@shillcoin.info", email.subject, email.body);
  };

  /*
   * Helper methods to generate mailto string
   * encodeText
   * - encodes newline with `%0d`
   * - encodes space with `%20`
   */
  static encodeText(text) {
    return text.split("\n").join("%0a").split(" ").join("%20");
  }
  static buildMailtoString(receiverEmail, subject, body) {
    return `mailto:${receiverEmail}?subject=${EmailHelper.encodeText(subject)}&body=${EmailHelper.encodeText(body)}`;
  };
};

export default EmailHelper;
