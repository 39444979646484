const Error404 = () => {
  return (
    <div className="error404-container">
      <div className="error404-title">
        <div className="glitch-wrapper">
          <div className="glitch" data-text="Page Not Found">Page Not Found</div>
        </div>
      </div>
      <div className="error404-title">
        <div className="glitch-wrapper glitch-wrapper-yellow">
          <div className="glitch" data-text="Error 404">Error 404</div>
        </div>
      </div>
      <div className="error404-text">
        We are sorry, something went wrong. Try again or reach out to
      </div>
      <a className="shillcoinbets-email-reachout" href="mailto:schillcoinbets@gmail.com">hello@shillcoin.info</a>
    </div>
  );
};

export default Error404;
