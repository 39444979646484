import { FaHeart, FaTelegramPlane, FaTwitter } from "react-icons/fa";
import { MdMail } from "react-icons/md";


const Footer = ({isMobile}) => {
  const isShillYourCoinPage = window.location.href.includes("/shill-your-coin");

  return isShillYourCoinPage ? null : (
    <div className="footer">
      <div className="footer">
        <div className="content flex align-center">
          Made with <span className="heart">{<FaHeart />}</span> by
          <a className="remove-all-link-styling personal-website-link" href="https://dcts.dev" target="_blank" rel="noreferrer">dcts</a> and
          <a className="remove-all-link-styling personal-website-link" href="https://mersadajanovic.com" target="_blank" rel="noreferrer">flamerge.</a>
          <span className="contact-text">Contact</span>
          <a href="mailto:hello@shillcoin.info">
            <span className="contact-icon flex align-center">{<MdMail />}</span>
          </a>
          <a href="https://t.me/joinchat/AnlmPgv_VHxiOWE0" target="_blank" rel="noreferrer">
            <span className="contact-icon flex align-center">{<FaTelegramPlane />}</span>
          </a>
          <a href="https://twitter.com/shillcoin_info" target="_blank" rel="noreferrer">
            <span className="contact-icon flex align-center">{<FaTwitter />}</span>
          </a>
        </div>
      </div>
    </div>

  );
};

export default Footer;
