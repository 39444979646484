import { useState, useRef } from "react";
import { Link } from "react-router-dom";
import shillcoinLogo from "../img/shillcoin-log.svg";
import EmailHelper from "./helper/EmailHelper";

const Nav = ({ isMobile }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);

  const isShillYourCoinPage = window.location.href.includes("/shill-your-coin");
  const navLinks = useRef(null);
  const handleMenu = () => {
    if (!menuIsOpen) {
      navLinks.current.classList.add("show");
      document.querySelector(".hamburger").classList.add("is-active");
    } else {
      navLinks.current.classList.remove("show");
      document.querySelector(".hamburger").classList.remove("is-active");
    }
    setMenuIsOpen(!menuIsOpen);
  };

  // add boxshadow on navbar when scrollposition is not 0
  document.addEventListener("scroll", (event) => {
    if (window.scrollY > 100) {
      document
        .querySelector(".nav-wrapper-sticky")
        .classList.add("box-shadow-on-scroll");
    } else {
      document
        .querySelector(".nav-wrapper-sticky")
        .classList.remove("box-shadow-on-scroll");
    }
  });

  const setNavState = (ev) => {
    const navbarState = ev.currentTarget.dataset.id || undefined;
    setSelectedItem(navbarState);
  }

  // template taken from basic example
  // https://stackoverflow.com/a/35165405/6272061
  const renderDesktop = () => {
    return (
      <nav className="desktop">
        <ul>
          <li onClick={setNavState}>
            <Link className="flex justify-center align-center" to="/" >
              <div className="nav-logo">
                <img src={shillcoinLogo} alt="Shillcoin logo" />
              </div>
              <div className="nav-title">shillcoin.info</div>
            </Link>
          </li>
          {!isShillYourCoinPage &&
            <>
              <li className={`${selectedItem === "ranking" ? "scaled" : ""} scale-on-hover`} data-id="ranking" onClick={setNavState}>
                <Link to="/ranking">Ranking</Link>
              </li>
              <li className={`${selectedItem === "faq" ? "scaled" : ""} scale-on-hover`} data-id="faq" onClick={setNavState}>
                <Link to="/faq">WTF?</Link>
              </li>
              <li className={`${selectedItem === "donate" ? "scaled" : ""} scale-on-hover`} data-id="donate" onClick={setNavState}>
                <Link to="/donate">Donate</Link>
              </li>
            </>
          }
          <li>
            { isShillYourCoinPage
              ? (
                <Link to="/shill-your-coin" className="last-item" id="submit-video-bttn">
                  <button id="submit-video-bttn">Donate</button>
                </Link>
              ) : (
                <a className="last-item"
                  target="_blank"
                  href="https://airtable.com/shr8h6rbZQGnuEzVC"
                  rel="noreferrer">
                  <button id="submit-video-bttn">Submit Video</button>
                </a>
              )
            }
          </li>
        </ul>
      </nav>
    );
  };
  const renderMobile = () => {
    return (
      <nav className="nav mobile">
        <div className="nav-menu">
          <div className="nav-info">
            <Link
              to="/"
              onClick={() => {
                if (menuIsOpen) handleMenu();
              }}
            >
              <div className="nav-logo">
                <img src={shillcoinLogo} alt="Shillcoin logo" />
              </div>
              <div className="nav-title">shillcoin.info</div>
            </Link>
          </div>
          <button
            className="hamburger hamburger--squeeze no-select"
            type="button"
            onClick={handleMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </div>
        <div className="nav-links" ref={navLinks}>
          <Link to="/ranking" onClick={handleMenu}>
            Ranking
          </Link>
          <Link to="/faq" onClick={handleMenu}>
            WTF?
          </Link>
          <button onClick={EmailHelper.sendMailtoSubmit}>Submit Video</button>
          <Link to="/donate" onClick={handleMenu}>
            <div className="flex justify-center align-center">
              <p>Donate</p>
              <svg
                className="heart-svg"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
              >
                <path d="M 9.5449219 4 C 5.9299219 4 3 6.9299219 3 10.544922 C 3 16.837321 10.298975 22.849799 13.708984 25.527344 A 2 2 0 0 0 13.71875 25.535156 C 13.742115 25.5535 13.773881 25.579629 13.796875 25.597656 L 13.798828 25.595703 A 2 2 0 0 0 15 26 A 2 2 0 0 0 16.203125 25.595703 L 16.203125 25.597656 C 16.209855 25.59238 16.219801 25.585381 16.226562 25.580078 C 16.231704 25.576045 16.23898 25.570455 16.244141 25.566406 A 2 2 0 0 0 16.263672 25.548828 C 19.663109 22.880904 27 16.852336 27 10.544922 C 27 6.9299219 24.070078 4 20.455078 4 C 17.000078 4 15 7 15 7 C 15 7 12.999922 4 9.5449219 4 z"></path>
              </svg>
            </div>
          </Link>
          <button onClick={EmailHelper.sendMailtoContact}>Contact</button>
        </div>
      </nav>
    );
  };

  return (
    <div>
      <div className="nav-wrapper-sticky">
        {isMobile ? renderMobile() : renderDesktop()}
      </div>
      <div className="nav-height-placeholder"></div>
    </div>
  );
};

export default Nav;
