import { Link } from "react-router-dom";
import Particles from 'react-particles-js';

const Home = ({ isMobile }) => {
  // send push notification if /andrei was opened
  if (document.location.href.includes("/andrei")) {
    fetch("https://us-central1-liist-prod.cloudfunctions.net/sendSlackNotification?message=🎉🎉🎉&channel=thomas");
  }
  // @TODO fetch data from database instead of hardcoding
  const youtubers = [
    {
      logoUrl: "https://yt3.ggpht.com/ytc/AAUvwnhbq6qVt2Keo8YRcWG8On6ecgxv3Qe0v1MJKrL6=s88-c-k-c0x00ffffff-no-rj",
      name: "BitBoy"
    },
    {
      logoUrl: "https://yt3.ggpht.com/ytc/AAUvwnhNcSXolX-PBpgs3oX3oX9afMCE8knwDks8heqVqA=s88-c-k-c0x00ffffff-no-rj",
      name: "Crypto Mobster"
    },
    {
      logoUrl: "https://yt3.ggpht.com/ytc/AAUvwni-2auyTuWwzxnOsFFZ-cEk67gxUng8iSCFadAeIw=s88-c-k-c0x00ffffff-no-rj",
      name: "IvanOnTech"
    },
    {
      logoUrl: "https://yt3.ggpht.com/ytc/AAUvwngCdv3L5634-nazsWmlOXuzRfirvI1pVl73KB9YsA=s88-c-k-c0x00ffffff-no-rj",
      name: "EllioTrades"
    },
    {
      logoUrl: "https://yt3.ggpht.com/ytc/AAUvwnhCdgxVfe-I13RHm5e_CJfxdZ5EWSvWyPmBiVSUnQ=s88-c-k-c0x00ffffff-no-rj",
      name: "Suppoman"
    },
    {
      logoUrl: "https://yt3.ggpht.com/ytc/AAUvwnjxShHbyaZ4sDV1pHELl826QbwwUJfr3GqeXPlO=s88-c-k-c0x00ffffff-no-rj",
      name: "Coin Bureau"
    },
    {
      logoUrl: "https://yt3.ggpht.com/ytc/AAUvwnjJHHFl16bgTlVs44ML6J0DC-sj97r2-ads_1oa=s176-c-k-c0x00ffffff-no-rj-mo",
      name: "Lark Davis"
    },
    {
      logoUrl: "https://yt3.ggpht.com/ytc/AAUvwnieQ2m9ul9FiOnukb3M9MjSY8nqTxvy0yfAMqVk=s88-c-k-c0x00ffffff-no-rj",
      name: "Altcoin Daily"
    },
    {
      logoUrl: "https://yt3.ggpht.com/ytc/AAUvwnh7rAIB_jZcCiqWULKiQ34ed9Va6qE3AdJPqDSdhQ=s176-c-k-c0x00ffffff-no-rj-mo",
      name: "Sheldon Evans"
    },
    {
      logoUrl: "https://yt3.ggpht.com/ytc/AAUvwnjGqV7Zw8eHu5xpYcNWRua6eLDXz_OGjC7hMiuH=s240-c-k-c0x00ffffff-no-rj",
      name: "CryptoWendyO"
    },
  ]

  const particleCount = Math.max(30, Math.round(window.innerWidth * window.innerHeight / 20000));
  return (
    <div className="home-container">
      <div className="particles-container">
        {!isMobile && ( // only show particles on desktop
          <Particles
            params={{
              "particles": {
                "number": {
                  "value": particleCount
                },
                "size": {
                  "value": 3
                },
                "move": {
                  "speed": 3
                },
              },
            }}
          />
        )}
      </div>

      <div className="home-flex">
        <div className="item item-description">
          <h1>We evaluate <br></br>altcoin picks by <br></br><span className="element">youtubers...</span></h1>
          <p className="description-text">
            ...by calculating <strong>EXACTELY</strong> how much money you would have made or lost investing in the coins that were called!
          </p>
          <div className="see-ranking-bttn-containter">
            <Link to="/ranking">See Ranking</Link>
          </div>
        </div>

        <div className="item item-youtubers">
          <div>
            {
              youtubers.map((youtuber, indx) => {
                return (
                  <YoutubeAvatar key={indx} youtuber={youtuber} />
                )
              })
            }
          </div>
          <div className="flex justify-center align-center">
            <p className="and-many-more">and many more...</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const YoutubeAvatar = ({ youtuber }) => {
  const { logoUrl, name } = youtuber;
  return (
    <div className="youtuber-avatar-container">
      <img className="youtuber-avatar" src={logoUrl} alt={name} />
      <div className="tooltip-content">
        <h1 className="text-elipsis">{name}</h1>
        <a href={`/ranking?keyword=${name}`}>
          <div className="open-youtuber-page-bttn text-elipsis">See Videos</div>
        </a>
      </div>
    </div>
  );
}

export default Home;
