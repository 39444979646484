import { Link } from "react-router-dom";

const EmailConfirmation = () => {
  return (
    <div>
      <div className="error404-container">
        <div className="error404-title">
          Email verified
        </div>
        <div className="error404-text">
          Your email has been verified 🎉
        </div>
      </div>
      <div className="center-button">
        <div className="shillcoin-bttn">
          <Link to="/ranking">Back to Ranking</Link>
        </div>
      </div>
    </div>
  );
};

export default EmailConfirmation;
