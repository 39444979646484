import firebase from "firebase/app";
import "firebase/auth";

// firebase.auth().onAuthStateChanged((user) => {
//   if (user) {
//     console.log("user is signed in");
//     console.log(user);
//     // User is signed in.
//   } else {
//     console.log("user is NOT signed in");
//     console.log(user);
//     // No user is signed in.
//   }
// });

const AdminLogin = () => {
  const login = async (el) => {
    el.preventDefault();
    const username = document.querySelector("input#username").value;
    const password = document.querySelector("input#password").value;
    console.log(`username: ${username}`);
    console.log(`username: ${password}`);
    try {
      const userCredential = await firebase.auth().signInWithEmailAndPassword(username, password);
      let user = userCredential.user;
      console.log(user);
      // redirect to AdminDashboard

    } catch(error) {
      let errorCode = error.code;
      let errorMessage = error.message;
      console.log(errorCode);
      console.log(errorMessage);
    }
  }

  return (
    <div className="home-container">

      {/* TITLE */}
      <div className="login-title">
        Admin Panel <span>Login</span>
      </div>

      {/* LOGIN FORM */}
      <div className="login-container">
        <form onSubmit={login}>
          <div className="input-element">
            {/* <label htmlFor="user">Username</label> */}
            <input id="username" type="text" defaultValue="" name="user" placeholder="enter username"/>
          </div>
          <div className="input-element">
            {/* <label htmlFor="password">Password</label> */}
            <input id="password" type="password" defaultValue="" name="password" placeholder="enter password"/>
          </div>
          <input type="submit" id="login" value="login"/>
        </form>
      </div>

    </div>
  );
};

export default AdminLogin;
