import EmailHelper from "./helper/EmailHelper";

const Contact = () => {
  return (
    <div className="contact-container">
      <h1>You have something you want to tell us?</h1>
      <button
        className="contact-button pointer"
        onClick={EmailHelper.sendMailtoContact}
      >
        Get in touch
      </button>
    </div>
  );
};

export default Contact;
