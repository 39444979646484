import { Link } from "react-router-dom";

const AdminDashboard = () => {
  return (
    <div className="home-container">
      <div className="home-title">
        Admin <span>Dash</span>
      </div>
      <Link to="/">Logout</Link>
    </div>
  );
};

export default AdminDashboard;
