const faq = [
  {
    question: "I don’t understand the purpose of this website?",
    answer:
      "There are currently so many videos on youtube about the best altcoin picks for altseason. We simply collect all those callouts and track how they perform over time by computing a ROI (return on investment) for every video. The higher the ROI, the better the prediction was.",
  },
  {
    question: "How is the ROI measured?",
    answer:
      "The ROI is calculated for every video as following: Had you bought all altcoins mentioned in the video on the day the video was published, how much gains in % would you have today? We currently calculate solely based on USD values, but will add relative comparisons to BTC and ETH in the near future.",
  },
  {
    question: "How is the data gathered?",
    answer:
      "Everytime we see an altcoin shill video on youtube, we add it to our database. The computation of the ROI is then updated in real time automatically using the coingecko prices api (for current and historic prices).",
  },
  {
    question: "Why is video XYZ not listed?",
    answer:
      "We try our best to list all videos out there. In case you find a video that is currently not listed, we encourage you to send us the videolink via email to hello@shillcoin.info. We promise to add it within 24h. Only ensure that the videos follow our requirements (see next question).",
  },
  {
    question: "What are the requirements for a video to be listed?",
    answer:
      "(1) The video should callout a minimum of 3 altcoins (bitcoin excluded), and (2) there has to be some amount of shilling involved.",
  },
  {
    question: "What does 'shilling' mean? Is 'shilling' bad?",
    answer:
      "There are lots of definitions what a 'shill' is out there. But mostly we agree with Digital Asset News, a 'shill' is someone that belives and supports a certain project. So shilling is nothing bad per se. Please be aware that there is also the other side of shilling, where the 'shill' takes money without disclosing it. While we support the first type of 'shill', we discourage the bad shills. See Digital Asset news video here: https://youtu.be/Rcmy2tTVfZM?t=341",
  },
  {
    question: "Who picks the videos?",
    answer:
      "Currently its just us, but we would love to build a community of altcoin enthusiasts to help us finding and indexing videos. Please reach out if you are interested in helping us: hello@shillcoin.info.",
  },
  {
    question: "What is an altcoin?",
    answer:
      "Bitcoin was the first cryptocurrency created, but since it’s inception lots of other coins have emerged, which are called altcoins (= alternative coin).",
  },
  {
    question: "What is your goal? Why are you doing this?",
    answer: "We love predictions in general and want to add transparency and accountability to the crazyness of altcoin mania! To help you keep FOMO in check and make elaborate, data driven investment decisions. Also it's just fun seeing some picks being absolute winners, and others fail miserably.",
  },
  {
    question: "What are your values?",
    answer:
      "Free internet, decentralization and technological progress. We belive decentralization will fundamentally transform how humans organize and collaborate. Blockchain is a first step in that direction. ",
  },
  {
    question: "Which price data are you using?",
    answer: "We use the free coingecko API, which allows us to get historic prices. Big shoutout to Coingecko for providing that data completely for free.",
  },
];

const Faq = () => {
  return (
    <div className="faq-container">
      <h1>WTF?</h1>
      <div className="faq-list">
        {faq.map((qAndA, index) => {
          const { question, answer } = qAndA;
          return (
            <div key={index}>
              <h3>{question}</h3>
              <div className="faq-answer">{answer}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Faq;
