const ShillYourCoin = () => {
  return (
    <div className="shill-your-coin-container">
      <h1>Shill Your Coin 🚀</h1>

      <h2>Game Rules</h2>
      <ol>
        <li>Submit your favorite altcoin</li>
        <li>We track the price of that coin off chain</li>
        <li>Every day we update the coin prices, and the coin with the best performance wins</li>
        <li>1 submission per wallet, if you send multiple submissions, the last one overrides previous submissions.</li>
      </ol>
      <form action="submit">
        <input type="text" placeholder="Search Coin ..."/>
        <input type="text" placeholder="Your Name (optional)"/>
        <input type="submit" value="Submit"/>
      </form>

    </div>
  );
};

export default ShillYourCoin;
