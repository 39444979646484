import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Nav from "./Nav";
import Footer from "./Footer";
import Home from "./Home";
import Contact from "./Contact";
import Faq from "./Faq";
import Ranking from "./Ranking";
import Donate from "./Donate";
import ShillYourCoin from "./ShillYourCoin";
import EmailConfirmation from "./EmailConfirmation";
import EmailUnsubscription from "./EmailUnsubscription";
import Error404 from "./Error404";

// AdminPanel pages
import AdminLogin from "./admin/AdminLogin";
import AdminDashboard from "./admin/AdminDashboard";

// firebase SDK
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";
import { useState, useEffect } from "react";

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAEygIG7B48mvxkm13bcHIzn3kJZqTK1kY",
  authDomain: "shillcoin.firebaseapp.com",
  projectId: "shillcoin",
  storageBucket: "shillcoin.appspot.com",
  messagingSenderId: "265713696512",
  appId: "1:265713696512:web:b57aac094610a4ea09fde5",
};
firebase.initializeApp(firebaseConfig);
firebase.analytics();

const App = () => {
  const [videos/*, setVideos*/] = useState([]);
  const [metadata/*, setMetadata*/] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  window.addEventListener('resize', () => {
    setIsMobile(window.innerWidth <= 850);
    setViewportWidth(window.innerWidth);
  });

  useEffect(() => {
    (async () => {
      // const fetchVideosCallable = firebase
      //   .functions()
      //   .httpsCallable("fetchRanking");
      // const result = await fetchVideosCallable();
      // const videos = result.data.videos;
      // const metadata = result.data.meta;
      // setVideos(videos);
      // setMetadata(metadata);
      setIsLoading(false);
      setIsError(true);
    })();
  }, []);

  return (
    <Router>
      <div className="flex-wrapper">
        <div className="container">
          <div className="main">
            <Nav
              isMobile={isMobile}
            />
            <div className="main-content">
              <Switch>
                <Route exact path="/">
                  <Home
                    isMobile={isMobile} // show particles only on desktop
                  />
                </Route>
                <Route exact path="/andrei">
                  <Home
                    isMobile={isMobile} // show particles only on desktop
                  />
                </Route>
                <Route exact path="/contact">
                  <Contact />
                </Route>
                <Route exact path="/faq">
                  <Faq />
                </Route>
                <Route exact path="/ranking">
                  <Ranking
                    videos={videos}
                    metadata={metadata}
                    isError={isError}
                    isLoading={isLoading}
                    isMobile={isMobile}
                    viewportWidth={viewportWidth}
                  />
                </Route>
                <Route exact path="/donate">
                  <Donate />
                </Route>
                <Route exact path="/admin/login">
                  <AdminLogin />
                </Route>
                <Route exact path="/admin/dashboard">
                  <AdminDashboard />
                </Route>
                <Route exact path="/emailConfirmation">
                  <EmailConfirmation />
                </Route>
                <Route exact path="/emailUnsubscription">
                  <EmailUnsubscription />
                </Route>
                <Route exact path="/404">
                  <Error404 />
                </Route>
                <Route exact path="/shill-your-coin">
                  <ShillYourCoin />
                </Route>
                {/*
                <Route exact path="/video/:id">
                  <Video id={params.id} />
                </Route>
                */}
                <Route path="*">
                  <Error404 />
                </Route>
              </Switch>
            </div>
          </div>
        </div>
        <Footer
          isMobile={isMobile}  // smaller footer on mobile
        />

      </div>
    </Router>
  );
};

export default App;
